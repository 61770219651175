<template>
  <div>
    <associatedOption
      :fieldAttributes="{
        name: fieldAttributes.name,
        options: operators,
        ...fieldAttributes,
      }"
      :type="fieldAttributes.type"
      v-on="$listeners"
      :value="value"
      :disabled="checkReadOnly"
      :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  components: { associatedOption },
  props: ["result", "fieldAttributes", "checkReadOnly", "value"],
  computed: {
    operators() {
      //console.log(this.result.valueLine.type);
      let operator = [];
      if(this.result.valueLine.type=='output' || this.result.valueLine.type=='stderr'){
        operator = options.exScriptOutOperators
      }else{ // exitCode / scriptRuntime
        operator = options.exScriptCodeOperators
      }
      return operator;
    },
  },
};
</script>